@import '../../styles/index';

.base {
  margin: 0 auto;
  width: fit-content;
  max-width: 100%;
}

.zip-code-field {
  width: 20.625rem;
  max-width: 100%;
  margin-inline: auto;
}

.box {
  padding-inline: 3rem;
  text-align: center;
  width: 43rem;
  max-width: 100%;
}

.headline {
  margin: 0;
  padding: 0;
  font-size: $font-size-24;
  font-weight: $font-weight-bold;
  line-height: 1.35;
  color: $color-black--100;
}
